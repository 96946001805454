document.addEventListener('DOMContentLoaded', (e) => {
	(function averageRating() {
		const ratingNumbers = document?.querySelectorAll('[data-rating-digit]');

		const votes = document?.querySelector('.votes');
		const counter = document?.querySelector('.tab .counter');
		const score = document?.querySelector('[data-rating-score]');
		const galleryBody = document?.querySelector('.gallery-body');

		let averageNum;

		function average(array) {
			averageNum = array.reduce((a, b) => a + b) / array.length;
		}

		if (ratingNumbers.length > 0) {
			const numbers = [];

			ratingNumbers.forEach((item) => {
				numbers.push(+item.textContent);
			});

			average(numbers);

			const averageNumRounded = Math.round(averageNum * 10) / 10;

			votes.innerHTML = numbers.length;
			counter.innerHTML = numbers.length;
			score.innerHTML = averageNumRounded;

			localStorage.setItem('reviews-quantity', numbers.length);
			localStorage.setItem('average-num', averageNumRounded);
		}

		if (galleryBody) {
			const reviewsQuantity = localStorage.getItem('reviews-quantity');
			const averageNumRounded = localStorage.getItem('average-num');

			if (reviewsQuantity > 0) {
				votes.innerHTML = +reviewsQuantity;
				counter.innerHTML = +reviewsQuantity;
			}

			if (averageNumRounded > 0) {
				score.innerHTML = averageNumRounded;
			}
		}
	})();

	const burger = () => {
		const burgerBtn = document?.querySelector('#header-menu-btn');
		const mobileRow = document?.querySelector('[data-mobile-row]');
		const mobilePopup = document?.querySelector('[data-mobile-popup]');

		burgerBtn.addEventListener('click', (e) => {
			mobileRow.classList.toggle('active');
			mobilePopup.classList.toggle('block');
		});
	};
	burger();

	const commerceHide = () => {
		const buttons = document?.querySelectorAll('[data-commerce-close]');

		if (buttons.length) {
			buttons.forEach((item) => {
				item.addEventListener('click', (e) => {
					const parent = e.target.closest('[data-commerce-three]');

					parent.classList.add('display-none');
				});
			});
		}
	};
	commerceHide();
});
